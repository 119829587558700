import React from 'react';
//style
import styled, { css } from 'styled-components';
import { between, rem, rgba } from 'polished';
import vars from './varss';
//components
import Container from './container';

const FooterWrap = styled.footer`
  position: relative;
  z-index: 2;
  padding-top: ${rem('120px')};
  padding-bottom: ${rem('20px')};
  @media (max-width: ${vars.media.lgMax}) {
    padding-top: ${between('60px', '120px')};
  }
  @media (max-width: ${vars.media.smMax}) {
    padding-bottom: 0;
  }
`;

const FooterTexts = styled.div`
  text-align: center;
  padding-bottom: ${rem('150px')};
  @media (max-width: ${vars.media.lgMax}) {
    padding-bottom: ${between('100px', '150px')};
  }
  p {
    font-size: ${rem('24px')};
    margin-bottom: ${rem('20px')};
    font-weight: 300;
    line-height: 1.25;
    @media (max-width: ${vars.media.lgMax}) {
      font-size: ${between('20px', '24px')};
      margin-bottom: ${between('12px', '20px')};
    }
    span {
      font-weight: normal;
    }
  }
`;

const FooterEmail = styled.a`
  display: inline-block;
  font-size: ${rem('50px')};
  text-transform: uppercase;
  font-weight: 300;
  @media (max-width: ${vars.media.lgMax}) {
    font-size: ${between('28px', '50px')};
  }
  @media (max-width: 400px) {
    font-size: ${rem('24px')};
  }
  &::before {
   height: 2px;
  }
  /* dark */
  ${props => props.dark && css`
    color: #fff;
    &::before {
      background-color: #fff;
    }
    &:hover {
      color: #fff;
    }
  `}
  /* active */
  ${props => props.active && css`
    color: ${rgba('#fff', 0.1)};;
    &:hover {
      color: ${rgba('#fff', 0.1)};;
    }
  `}
`;

const FooterBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  P {
    font-size: ${rem('18px')};
    line-height: 0.92;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    a {
      margin-left: 5px;
    }
    + p {
      margin-top: 0;
    }
  }
  @media (max-width: ${vars.media.smMax}) {
    display: none;
  }
`;

const FooterLinks = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: ${rem('8px')};
  @media (min-width: ${vars.media.mdMin}) {
    display: none;
  }
  li {
    min-width: 50%;
    padding-left: 10px;
    padding-right: 10px;
    a {
      font-size: ${rem('18px')};
      color: #000;
      margin-bottom: ${rem('12px')};
      text-transform: uppercase;
      letter-spacing: 1px;
      &:hover {
        color: #000;
      }
    }
  }
`;

const Footer = ({ data: { email } }) => {
  return (
    <FooterWrap>
      <Container>
        <FooterTexts>
          <p>Got a project in mind? <span>~ Let’s talk.</span></p>
          <FooterEmail className="link-line" href={`mailto: ${email}`}>{email}</FooterEmail>
        </FooterTexts>
        <FooterBottom>
          <p className="year">{new Date().getFullYear()}</p>
          <p className="coded">Coded by <a href="http://dongepulango.com/" target="_blank" rel="noreferrer" className="link-line">Don Gepulango</a></p>
        </FooterBottom>
        <FooterLinks>
          <li>
            <a href="https://twitter.com/jakubreis" target="_blank" rel="noopener noreferrer" className="link-line">Twitter</a>
          </li>
          <li>
            <a href="https://www.instagram.com/jakubreis/" target="_blank" rel="noopener noreferrer" className="link-line">Instagram</a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/jakub-reis-92026764/" target="_blank" rel="noopener noreferrer" className="link-line">LinkedIn</a>
          </li>
          <li>
            <a href="https://dribbble.com/jakubreis" target="_blank" rel="noopener noreferrer" className="link-line">Dribbble</a>
          </li>
        </FooterLinks>
      </Container>
    </FooterWrap>
  );
};

export default Footer;