import React from 'react';
//gatsby
import { graphql } from 'gatsby';
//gatsby image
import { GatsbyImage, getImage } from "gatsby-plugin-image";
//styles
import styled from 'styled-components';
import { between, rem } from 'polished';
import vars from '../components/varss';
//components
import Seo from '../components/seo';
import Layout from '../components/layout';
import Header from '../components/header';
import Footer from '../components/footer';
import Container from '../components/container';
//grid
import { Row, Col } from 'styled-bootstrap-grid';

//styled
const CaseStudyWrap = styled.section`
  position: relative;
  min-height: 100vh;
`;

const CaseStudyHero = styled.section`
  position: relative;
  padding-top: ${rem('100px')};
  padding-bottom: ${rem('100px')};
  @media (max-width: ${vars.media.lgMax}) {
    padding-top: ${between('40px', '100px')};
    padding-bottom: ${between('40px', '100px')};
  }
  h1 {
    font-size: ${rem('50px')};
    line-height: 1;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 0;
    @media (max-width: ${vars.media.lgMax}) {
      font-size: ${between('38px', '50px')};
    }
  }
`;

const CaseStudyHeroTexts = styled.div`
  > div {
    display: flex;
    align-items: flex-start;
    h2 {
      min-width: 100px;
      width: 100px;
      font-size: ${rem('12px')};
      line-height: ${rem('32px')};
      font-weight: normal;
      text-transform: uppercase;
      margin-bottom: 0;
    }
    p {
      font-size: ${rem('24px')};
      line-height: 1.33;
      font-weight: 300;
      @media (max-width: ${vars.media.lgMax}) {
        font-size: ${between('20px', '24px')};
      }
    }
    &:not(:last-child) {
      margin-bottom: ${rem('60px')};
      @media (max-width: ${vars.media.lgMax}) {
        margin-bottom: ${between('30px', '60px')};
      }
    }
  }
  &.top {
    @media (max-width: ${vars.media.smMax}) {
      display: none;
    }
  }
  &.bottom {
    @media (min-width: ${vars.media.mdMin}) {
      display: none;
    }
    @media (max-width: ${vars.media.smMax}) {
      padding: 40px 15px;
      > div {
        display: block;
      }
    }
  }
`;

const CaseStudyHeroImage = styled.section`
  margin-bottom: ${rem('20px')};
  @media (max-width: ${vars.media.smMax}) {
    margin-left: -16px;
    margin-right: -16px;
  }
`;

const CaseStudyImages = styled.section`
  padding-bottom: ${rem('60px')};
  .image {
    margin-bottom: ${rem('20px')};
    @media (max-width: ${vars.media.smMax}) {
      margin-bottom: ${rem('16px')};
    }
  }
`;

const CaseStudy = (props) => {

  //data
  const siteData = props.data.site;
  const caseStudyData = props.data.markdownRemark.frontmatter;

  return (
    <React.Fragment>
      <Header data={siteData.siteMetadata} />
      <Layout>
        <Seo title={`Jakub Reis — ${caseStudyData.title}`} />
        <CaseStudyWrap >

          <CaseStudyHero>
            <Container>
              <Row>
                <Col md={5}>
                  <h1>{caseStudyData.title}</h1>
                </Col>
                <Col md={7}>
                  <CaseStudyHeroTexts className="top">
                    <div>
                      <h2>Client</h2>
                      <p>{caseStudyData.title}</p>
                    </div>
                    <div>
                      <h2>Services</h2>
                      <p dangerouslySetInnerHTML={{ __html: `${caseStudyData.subtitle}` }}></p>
                    </div>
                    <div>
                      <h2>About</h2>
                      <p>{caseStudyData.about}</p>
                    </div>
                  </CaseStudyHeroTexts>
                </Col>
              </Row>
            </Container>
          </CaseStudyHero>

          <CaseStudyHeroImage>
            <Container>
              <GatsbyImage
                image={getImage(caseStudyData.hero)}
                alt={caseStudyData.title}
              />
            </Container>
          </CaseStudyHeroImage>

          <CaseStudyHeroTexts className="bottom">
            <div>
              <h2>Client</h2>
              <p>{caseStudyData.title}</p>
            </div>
            <div>
              <h2>Services</h2>
              <p dangerouslySetInnerHTML={{ __html: `${caseStudyData.subtitle}` }}></p>
            </div>
            <div>
              <h2>About</h2>
              <p>{caseStudyData.about}</p>
            </div>
          </CaseStudyHeroTexts>

          <CaseStudyImages>
            <Container>
              <Row>
                {caseStudyData.images.map((item, index) => (
                  <Col sm={item.half ? 6 : 12} key={index} className="image">
                    <GatsbyImage
                      image={getImage(item.image)}
                      alt={`${caseStudyData.title} image ${index}`}
                    />
                  </Col>
                ))}
              </Row>
              
            </Container>
          </CaseStudyImages>

          <Footer data={siteData.siteMetadata} />
        </CaseStudyWrap>
      </Layout>
    </React.Fragment>
  );
};

export const query = graphql`
  query CaseStudyQuery($id: Int!) {
    site {
      siteMetadata {
        title
        description
        siteTitle
        email
      }
    }
    markdownRemark(frontmatter: {id: {eq: $id}}) {
      id
      frontmatter {
        id
        slug
        title
        subtitle
        about
        hero {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        images {
          half
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;

export default CaseStudy;