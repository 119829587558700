import React, { useContext } from 'react';
//router
//router
import { useLocation, navigate } from '@reach/router';
//context
import { GlobalContext } from '../context/context';
//style
import styled, { css } from 'styled-components';
import vars from './varss';
import { rem, between, rgba } from 'polished';
//components
import Container from './container';
//framer-motion
import { motion } from 'framer-motion';

const HeaderWrap = styled(motion.header)`
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding-top: ${rem('20px')};
  /* active */
  ${props => props.active && css`
    color: #fff;
  `}
`;

const HeaderFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const HeaderEmail = styled.a`
  display: block;
  font-size: ${rem('18px')};;
  color: #000;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  &:hover {
    color: #000;
  }
  @media (max-width: ${vars.media.lgMax}) {
    font-size: ${between('16px', '18px')};
  }
  /* active */
  ${props => props.active && css`
    color: ${rgba('#fff', 0.1)};;
    &:hover {
      color: ${rgba('#fff', 0.1)};;
    }
  `}
`;

const HeaderBack = styled.div`
  display: block;
  font-size: ${rem('18px')};;
  color: #000;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  @media (max-width: ${vars.media.lgMax}) {
    font-size: ${between('16px', '18px')};
  }
  &:hover {
    color: #000;
  }
`;

const HeaderLinks = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  @media (max-width: ${vars.media.smMax}) {
    display: none;
  }
  li {
    a {
      font-size: ${rem('18px')};;
      color: #000;
      margin-bottom: 10px;
      text-transform: uppercase;
      letter-spacing: 1px;
      &:hover {
        color: #000;
      }
    }
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
`;

//animation
const FadeUp = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      ease: "easeOut",
      duration: 1,
      delay: 0.2,
    }
  }
};

const Header = ({ data: { email } }) => {

  //use context
  const [context] = useContext(GlobalContext);

  //location
  const location = useLocation();

  const goBack = () => {
    navigate(-1);
  }

  return (
    <HeaderWrap
      initial={false}
      animate={context.loaded ? 'animate' : 'initial'}
      variants={FadeUp}>
      <Container maxWidth="100%">
        <HeaderFlex>
          {location.pathname === '/' ? (
            <HeaderEmail
              className="link-line" href={`mailto: ${email}`}
              active={context.clientHovered}>
              {email}
            </HeaderEmail>
          ) : (
            <HeaderBack onClick={goBack} className="link-line">
              Back
            </HeaderBack>
          )}
          <HeaderLinks>
            <li>
              <a href="https://twitter.com/jakubreis" target="_blank" rel="noopener noreferrer" className="link-line">Twitter</a>
            </li>
            <li>
              <a href="https://www.instagram.com/jakubreis/" target="_blank" rel="noopener noreferrer" className="link-line">Instagram</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/jakub-reis-92026764/" target="_blank" rel="noopener noreferrer" className="link-line">LinkedIn</a>
            </li>
            <li>
              <a href="https://dribbble.com/jakubreis" target="_blank" rel="noopener noreferrer" className="link-line">Dribbble</a>
            </li>
          </HeaderLinks>
        </HeaderFlex>
      </Container>
    </HeaderWrap>
  );
};

export default Header;